import React from 'react';
import PropTypes from 'prop-types';

import LikeBox from '../likeBox';
import {
  MainContainer,
  PostCardBody,
  PostCardLikes,
  PostCardDays,
  PostCardImageContainer,
  PostCardText,
  PostCardSignature,
} from './style';
import POST_CARDS_CONTENT from './content';

const PostCard = ({ behind, index }) => {
  const { days, image, text, signature, heartCount, hugCount, sparkleCount } =
    POST_CARDS_CONTENT[index];

  return (
    <MainContainer behind={behind}>
      <PostCardBody>
        <PostCardDays>{days}</PostCardDays>
        <PostCardImageContainer>{image}</PostCardImageContainer>
        <PostCardText>{text}</PostCardText>
        <PostCardSignature>{signature}</PostCardSignature>
      </PostCardBody>
      <PostCardLikes>
        <LikeBox type="heart" count={heartCount} />
        <LikeBox type="hug" count={hugCount} />
        <LikeBox type="sparkle" count={sparkleCount} />
      </PostCardLikes>
    </MainContainer>
  );
};

PostCard.propTypes = {
  behind: PropTypes.bool,
  index: PropTypes.number.isRequired,
};

export default PostCard;

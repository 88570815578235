import styled from '@emotion/styled';
import { SPACER, MEDIA_QUERIES, GRID } from 'radiance-ui/lib/constants';
import { css } from '@emotion/react';

import retina from 'styles/mixins/retinaBackgroundImage';
import {
  LOCAL_COLORS,
  LOCAL_TYPOGRAPHY_CONSTANTS,
  LOCAL_SPACING,
  LOCAL_BOX_SHADOWS,
} from 'styles/constants';

import providerBackgroundImage from './content/images/providerBackground.jpg';
import providerBackgroundImageRetina from './content/images/providerBackground@2x.jpg';

export const ProviderBox = styled.div`
  height: 650px;
  position: relative;
  z-index: 3;
  padding: ${SPACER.x4large} ${SPACER.xlarge};
  margin: 0 auto;
  max-width: ${GRID.xlContentMaxWidth};
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;

  ${MEDIA_QUERIES.xsUp} {
    height: 675px;
  }

  ${MEDIA_QUERIES.mdUp} {
    height: 700px;
    flex-flow: row-reverse nowrap;
    align-items: flex-start;
  }
`;

export const TextCol = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  ${MEDIA_QUERIES.mdUp} {
    margin-bottom: 150px;
    align-self: center;
  }
`;

export const Header = styled.h3`
  font-size: ${LOCAL_TYPOGRAPHY_CONSTANTS.fontSize.titleEmphasisSm};
  font-weight: ${({ theme }) => theme.TYPOGRAPHY.fontWeight.normal};
  line-height: 1.2;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.COLORS.primary};

  ${MEDIA_QUERIES.mdUp} {
    font-size: ${LOCAL_TYPOGRAPHY_CONSTANTS.fontSize.titleMd2};
    max-width: 290px;
    padding-left: ${LOCAL_SPACING.base};
  }

  ${MEDIA_QUERIES.lgUp} {
    padding-left: 0px;
  }
`;

export const Subheader = styled.p`
  color: ${LOCAL_COLORS.purple75};
  font-size: ${LOCAL_TYPOGRAPHY_CONSTANTS.fontSize.headerBody};
  line-height: 1.5;
  padding-right: ${SPACER.xlarge};

  ${MEDIA_QUERIES.mdUp} {
    max-width: 290px;
    padding-left: ${LOCAL_SPACING.base};
  }

  ${MEDIA_QUERIES.lgUp} {
    padding-left: 0px;
  }
`;

export const ImageCol = styled.div`
  flex: 1;
  position: relative;
`;

export const ProviderBackground = styled.div`
  background: url(${providerBackgroundImage}) no-repeat;
  ${retina(providerBackgroundImageRetina, '498px')};
  display: none;
  height: 601px;
  position: absolute;
  right: 0;
  width: 498px;

  ${MEDIA_QUERIES.mdUp} {
    display: block;
  }
`;

export const ProviderChat = styled.div`
  background: ${({ theme }) => theme.COLORS.white};
  box-shadow: ${LOCAL_BOX_SHADOWS.communityCards};
  overflow: hidden;
  position: relative;
  height: 465px;
  width: 263px;
  top: 100px;

  ${MEDIA_QUERIES.mdUp} {
    top: 65px;
    left: 45px;
    margin: auto 0 0 auto;
  }

  ${MEDIA_QUERIES.lgUp} {
    left: 75px;
  }
`;

export const ProviderImageBox = styled.div`
  background: ${LOCAL_COLORS.lightWhiteBackground};
  height: 180px;
  overflow: hidden;
`;

export const ProviderMessages = styled.div`
  padding: 30px 0;
`;

export const MessageBox = styled.div<{ visible?: boolean; reverse?: boolean }>`
  transition: 0.35s;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transform: ${({ visible }) =>
    visible ? 'translateY(0)' : 'translateY(50px)'};
  margin-bottom: 15px;
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  justify-content: flex-start;
  align-items: center;
`;

export const MessageThumbnailBox = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 45px;
`;

export const imageThumbnailStyles = css`
  border-radius: 50%;
  height: 25px;
  margin: 0 ${SPACER.small};
  width: 25px;
`;

export const Message = styled.div<{ reverse?: boolean }>`
  margin: ${({ reverse }) =>
    reverse ? `0 0 0 ${LOCAL_SPACING.base}` : `0 ${LOCAL_SPACING.base} 0 0`};
  padding: ${SPACER.small} ${SPACER.medium};
  border: 0.5px solid ${({ theme }) => theme.COLORS.border};
  color: ${LOCAL_COLORS.lightBlack};
  font-size: ${({ theme }) => theme.TYPOGRAPHY.fontSize.caption};
  line-height: 1.5;
`;

import React from 'react';
import { useLocation } from '@gatsbyjs/reach-router';

import { Nav } from 'components/global/Nav';
import { SEO } from 'components/global/SEO';
import { Footer } from 'components/global/Footer';
import Header from 'components/pages/community/header';
import Provider from 'components/pages/community/provider';
import PostCards from 'components/pages/community/postCards';
import Social from 'components/pages/community/social';
import { Pricing, PRICING_CORE_CONTENT } from 'components/modules/Pricing';
import { ArticleCarousel } from 'components/modules/ArticleCarousel';
import { fetchRandomArticles } from 'utils/article/fetchArticles';
import useSetLandingPageUrlCookie from 'utils/hooks/useSetLandingPageUrlCookie';

const CommunityPage = () => {
  const { pathname } = useLocation();
  const randomArticles = fetchRandomArticles(pathname);
  useSetLandingPageUrlCookie();

  return (
    <React.Fragment>
      <SEO title="Curology - Community" />
      <Nav isTransparent />
      <Header />
      <Provider />
      <PostCards />
      <Social />
      <Pricing {...PRICING_CORE_CONTENT} />
      {!randomArticles?.isLoading && randomArticles?.response && (
        <ArticleCarousel content={randomArticles.response} />
      )}
      <Footer />
    </React.Fragment>
  );
};

export default CommunityPage;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';

import {
  LOCAL_BOX_SHADOWS,
  LOCAL_TYPOGRAPHY_CONSTANTS,
} from 'styles/constants';

const behindStyles = css`
  left: calc(100% - 75px);
  margin: 0;
  position: absolute;
  top: -55px;
  transform: rotate(-5deg);
  z-index: 1;

  ${MEDIA_QUERIES.mdUp} {
    left: calc(100% - 150px);
    top: 75px;
    margin-top: 0;
  }

  ${MEDIA_QUERIES.lgUp} {
    left: calc(100% - 350px);
  }
`;

export const MainContainer = styled.div<{ behind?: boolean }>`
  margin-left: ${SPACER.xlarge};
  z-index: 2;
  height: 450px;
  width: 263px;
  background: ${({ theme }) => theme.COLORS.white};
  box-shadow: ${LOCAL_BOX_SHADOWS.communitySocialPostCard};
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  position: relative;

  ${MEDIA_QUERIES.mdUp} {
    margin-top: 200px;
  }

  ${({ behind }) => (behind ? behindStyles : null)};
`;

export const PostCardBody = styled.div`
  flex: 1;
  padding: 25px 20px 0;

  ${MEDIA_QUERIES.mdUp} {
    padding: 25px 25px 0;
  }
`;

export const PostCardDays = styled.div`
  font-size: ${LOCAL_TYPOGRAPHY_CONSTANTS.fontSize.headerBody};
  margin-bottom: ${SPACER.large};
  color: ${({ theme }) => theme.COLORS.primary};
  letter-spacing: 1.5px;
  text-align: center;
  text-transform: uppercase;
`;

export const PostCardImageContainer = styled.div`
  height: 165px;
  width: 165px;
  margin: 0 auto ${SPACER.medium};
`;

export const PostCardText = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.COLORS.primary};
  display: flex;
  height: 75px;
  justify-content: center;
  line-height: 1.6;
  margin-bottom: ${SPACER.small};
  overflow: hidden;
  text-align: center;
  width: 100%;
  word-wrap: break-word;

  ${MEDIA_QUERIES.smUp} {
    height: 100px;
    padding: 0 ${SPACER.small};
  }
`;

export const PostCardSignature = styled.div`
  color: ${({ theme }) => theme.COLORS.primary};
  font-style: italic;
  margin: 0;
  text-align: center;
  line-height: 1.5;
`;

export const PostCardLikes = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex: 0 0 70px;
`;

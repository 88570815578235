import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const POST_CARDS_CONTENT = [
  {
    days: 'DAY 139',
    image: (
      <StaticImage
        src="./images/postcardImage1.jpg"
        alt="Curology community postcard"
      />
    ),
    text: 'I haven’t worn makeup in weeks!! I feel fantastic! Thanks curology 💖',
    signature: '- Kim',
    heartCount: 57,
    hugCount: 14,
    sparkleCount: 36,
  },
  {
    days: 'DAY 178',
    image: (
      <StaticImage
        src="./images/postcardImage2.jpg"
        alt="Curology community postcard"
      />
    ),
    text: '6 months!! Couldn’t be happier with my results',
    signature: '- Kim',
    heartCount: 73,
    hugCount: 11,
    sparkleCount: 34,
  },
];

export default POST_CARDS_CONTENT;

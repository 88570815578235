import useFetch from 'utils/hooks/useFetch';
import { GRAPHITE_API } from 'consts/externalApi';
import type {
  RandomArticlesData,
  RelatedArticlesData,
} from 'components/modules/ArticleCarousel';
import { SITE_URL } from 'consts/environmental';

export const fetchRelatedArticles = (path: string) => {
  const currentPage = `${SITE_URL}${path}`;
  const apiEndpoint = `${GRAPHITE_API.relatedArticles}?url=${currentPage}`;
  return useFetch<RelatedArticlesData>(apiEndpoint, {
    failSilently: true,
  });
};

export const fetchRandomArticles = (path: string) => {
  const apiEndpoint = `${GRAPHITE_API.randomArticles}?seed=${path}`;
  return useFetch<RandomArticlesData>(apiEndpoint, {
    failSilently: true,
  });
};

import React from 'react';

import PostCard from '../postCard';
import {
  PostCardsBox,
  TextCol,
  TextBox,
  Header,
  Text,
  FloatingText,
  CardsCol,
  DesktopBackgroundImage,
} from './style';

const PostCards = () => (
  <PostCardsBox>
    <TextCol>
      <TextBox>
        <Header>Need a boost of encouragement?</Header>
        <Text>Our members-only community cheers you on.</Text>
        <Text>
          Remember &#8211; results vary from person to person, because no two
          cases are alike!
        </Text>
        <FloatingText>15000+ postcards. 100+ posted daily</FloatingText>
      </TextBox>
    </TextCol>
    <CardsCol>
      <DesktopBackgroundImage />
      <PostCard index={0} />
      <PostCard behind index={1} />
    </CardsCol>
  </PostCardsBox>
);

export default PostCards;

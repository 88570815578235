import { useState, useEffect } from 'react';

import { notifyException } from 'utils/errorReporter';

interface UseFetchOptions {
  failSilently?: boolean;
}

const useFetch = <T>(url: RequestInfo, options: UseFetchOptions = {}) => {
  const [response, setResponse] = useState<null | T>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(url);
        const data = await res.json<T>();
        setResponse(data);
      } catch (error) {
        if (!options.failSilently) {
          notifyException(error);
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  return { response, isLoading };
};

export default useFetch;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type { ThemeType } from 'radiance-ui/lib/constants';
import { SPACER } from 'radiance-ui/lib/constants';

import { LOCAL_COLORS, LOCAL_TYPOGRAPHY_CONSTANTS } from 'styles/constants';

import { ReactComponent as HeartSVG } from './content/images/heart.svg';
import { ReactComponent as HugSVG } from './content/images/hug.svg';
import { ReactComponent as SparkleSVG } from './content/images/sparkle.svg';

export const LikeBoxContainer = styled.div`
  margin: 0 5px;
  padding: ${SPACER.medium};
  align-items: center;
  position: relative;
  display: flex;
  justify-content: center;
`;

export const LikeText = styled.span`
  color: ${LOCAL_COLORS.svgActive};
  font-size: ${LOCAL_TYPOGRAPHY_CONSTANTS.fontSize.xxsmall};
  left: 0;
  letter-spacing: 0.85px;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  top: 5px;
`;

export const LikeCount = styled.span`
  color: ${({ theme }) => theme.COLORS.primary};
  font-size: ${({ theme }) => theme.TYPOGRAPHY.fontSize.label};
  left: calc(100% - 13px);
  position: absolute;
  top: 15px;
`;

const iconStyles = (theme: ThemeType) => css`
  height: 27px;
  width: 25px;

  path,
  circle,
  polygon,
  polyline {
    stroke: ${theme.COLORS.primary};
    transition: 0.35s;
  }
`;

export const HeartIcon = styled(HeartSVG)`
  ${({ theme }) => iconStyles(theme)}
`;

export const HugIcon = styled(HugSVG)`
  ${({ theme }) => iconStyles(theme)}
`;

export const SparkleIcon = styled(SparkleSVG)`
  ${({ theme }) => iconStyles(theme)}
`;

import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import {
  ProviderBox,
  TextCol,
  Header,
  Subheader,
  ImageCol,
  ProviderBackground,
  ProviderChat,
  ProviderImageBox,
  ProviderMessages,
  MessageThumbnailBox,
  imageThumbnailStyles,
  Message,
} from './style';
import AnimatedMessageBox from './animatedMessageBox';

const Provider = () => (
  <ProviderBox>
    <TextCol>
      <Header>Have a private skincare question?</Header>
      <Subheader>Chat one-on-one with your personal provider.</Subheader>
    </TextCol>
    <ImageCol>
      <ProviderBackground />
      <ProviderChat>
        <ProviderImageBox>
          <StaticImage src="./content/images/provider.jpg" alt="" />
        </ProviderImageBox>
        <ProviderMessages>
          <AnimatedMessageBox reverse>
            <MessageThumbnailBox>
              <StaticImage
                src="./content/images/patientThumbnail.jpg"
                css={imageThumbnailStyles}
                alt="patient thumbnail"
              />
            </MessageThumbnailBox>
            <Message reverse>
              Morning! I uploaded new pics{' '}
              <span role="img" aria-label="icon">
                🙆
              </span>{' '}
              &nbsp;{' '}
              <span role="img" aria-label="icon">
                📸
              </span>
            </Message>
          </AnimatedMessageBox>
          <AnimatedMessageBox>
            <MessageThumbnailBox>
              <StaticImage
                src="./content/images/providerThumbnail.jpg"
                css={imageThumbnailStyles}
                alt="provider thumbnail"
              />
            </MessageThumbnailBox>
            <Message>
              Wow your skin looks great! I’m shipping you a new bottle.
            </Message>
          </AnimatedMessageBox>
          <AnimatedMessageBox reverse>
            <MessageThumbnailBox>
              <StaticImage
                src="./content/images/patientThumbnail.jpg"
                css={imageThumbnailStyles}
                alt="patient thumbnail"
              />
            </MessageThumbnailBox>
            <Message reverse>Thanks, you’re amazing!</Message>
          </AnimatedMessageBox>
        </ProviderMessages>
      </ProviderChat>
    </ImageCol>
  </ProviderBox>
);

export default Provider;

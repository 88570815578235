import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER, GRID } from 'radiance-ui/lib/constants';

import { LOCAL_TYPOGRAPHY_CONSTANTS, LOCAL_COLORS } from 'styles/constants';

import postcardBgImg from './content/images/postcardBg.png';

export const PostCardsBox = styled.div`
  background: ${LOCAL_COLORS.postcardGreen};
  height: 790px;
  padding: 100px 21% ${SPACER.x4large} ${SPACER.xlarge};
  z-index: 2;
  position: relative;
  overflow: hidden;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: ${GRID.xlContentMaxWidth};

  ${MEDIA_QUERIES.mdUp} {
    background: 0 0;
    height: 700px;
    padding-top: ${SPACER.x4large};
    padding-right: ${SPACER.xlarge};
    display: flex;
  }
`;

export const TextCol = styled.div`
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex: 1;
  padding: 0;
  position: relative;

  ${MEDIA_QUERIES.mdUp} {
    padding: 0 ${SPACER.small};
  }
`;

export const TextBox = styled.div`
  max-width: 290px;

  ${MEDIA_QUERIES.mdUp} {
    margin-bottom: 150px;
  }
`;

export const Header = styled.h3`
  color: ${({ theme }) => theme.COLORS.primary};
  font-size: ${LOCAL_TYPOGRAPHY_CONSTANTS.fontSize.titleEmphasisSm};
  font-weight: normal;
  line-height: 1.2;
  margin-bottom: ${SPACER.medium};

  ${MEDIA_QUERIES.mdUp} {
    font-size: ${LOCAL_TYPOGRAPHY_CONSTANTS.fontSize.titleMd2};
  }
`;

export const Text = styled.p`
  color: ${LOCAL_COLORS.purple75};
  font-size: ${LOCAL_TYPOGRAPHY_CONSTANTS.fontSize.headerBody};
  line-height: 1.5;
  margin-bottom: ${SPACER.medium};
`;

export const FloatingText = styled.span`
  bottom: -500px;
  left: -5px;
  right: auto;
  font-size: ${({ theme }) => theme.TYPOGRAPHY.fontSize.label};
  letter-spacing: 3px;
  position: absolute;
  text-align: center;
  transform: rotate(-90deg);
  transform-origin: left top 0;
  width: 450px;

  ${MEDIA_QUERIES.smUp} {
    right: -480px;
  }

  ${MEDIA_QUERIES.mdUp} {
    display: none;
  }
`;

export const CardsCol = styled.div`
  flex: 1;
  padding: 0;
  position: relative;

  ${MEDIA_QUERIES.mdUp} {
    padding: 0 ${SPACER.medium};
  }
`;

export const DesktopBackgroundImage = styled.div`
  background: url(${postcardBgImg}) no-repeat;
  display: none;
  height: 600px;
  left: -150px;
  position: absolute;
  width: 600px;
  z-index: -1;

  ${MEDIA_QUERIES.mdUp} {
    display: block;
  }
`;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SPACER, MEDIA_QUERIES, GRID } from 'radiance-ui/lib/constants';

import {
  LOCAL_COLORS,
  LOCAL_TYPOGRAPHY_CONSTANTS,
  LOCAL_BOX_SHADOWS,
} from 'styles/constants';

import socialBackgroundImage from './content/images/socialBackground.png';

export const SocialBox = styled.div`
  background: ${({ theme }) => theme.COLORS.primary};
  height: 800px;
  padding-top: 125px;
  padding: 125px 21% ${SPACER.x4large} ${SPACER.xlarge};
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: ${GRID.xlContentMaxWidth};
  overflow: hidden;

  ${MEDIA_QUERIES.xsUp} {
    height: 850px;
  }

  ${MEDIA_QUERIES.mdUp} {
    flex-direction: row;
    background: 0 0;
    height: 680px;
    padding-top: ${SPACER.x4large};
    padding-right: ${SPACER.xlarge};
  }
`;

export const TextCol = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex: 1;
  padding: 0;
  position: relative;

  ${MEDIA_QUERIES.mdUp} {
    padding: 0 ${SPACER.small};
  }
`;

export const TextBox = styled.div`
  max-width: 290px;

  ${MEDIA_QUERIES.mdUp} {
    margin-bottom: 150px;
  }
`;

export const Header = styled.h3`
  font-size: ${LOCAL_TYPOGRAPHY_CONSTANTS.fontSize.titleEmphasisSm};
  font-weight: ${({ theme }) => theme.TYPOGRAPHY.fontWeight.normal};
  line-height: 1.2;
  margin-bottom: ${SPACER.medium};
  color: ${({ theme }) => theme.COLORS.white};

  ${MEDIA_QUERIES.mdUp} {
    color: ${({ theme }) => theme.COLORS.primary};
    font-size: ${LOCAL_TYPOGRAPHY_CONSTANTS.fontSize.titleMd2};
  }
`;

export const Subheader = styled.p`
  color: ${({ theme }) => theme.COLORS.primaryTint3};
  font-size: ${LOCAL_TYPOGRAPHY_CONSTANTS.fontSize.headerBody};
  line-height: 1.5;

  ${MEDIA_QUERIES.mdUp} {
    padding-right: ${SPACER.xlarge};
    color: ${LOCAL_COLORS.purple75};
  }
`;

export const ImageCol = styled.div`
  flex: 1;
  position: relative;

  ${MEDIA_QUERIES.mdUp} {
    padding: 0 ${SPACER.small};
  }
`;

export const SocialBackground = styled.div`
  background: url(${socialBackgroundImage}) no-repeat;
  display: none;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;

  ${MEDIA_QUERIES.mdUp} {
    display: block;
    width: 350px;
    height: 498px;
  }

  ${MEDIA_QUERIES.lgUp} {
    width: 498px;
  }
`;

export const socialImageStyles = css`
  width: 260px;
  box-shadow: ${LOCAL_BOX_SHADOWS.communityCards};
  display: block;
  margin-top: ${SPACER.x4large};
  position: relative;

  ${MEDIA_QUERIES.mdUp} {
    margin: 100px auto;
  }
`;

import { useEffect } from 'react';

import { captureLandingPageUrl } from 'utils/captureReferrer';

const useSetLandingPageUrlCookie = () => {
  useEffect(() => {
    captureLandingPageUrl();
  }, []);
};

export default useSetLandingPageUrlCookie;

import React from 'react';
import PropTypes from 'prop-types';

import {
  LikeBoxContainer,
  LikeText,
  LikeCount,
  HeartIcon,
  HugIcon,
  SparkleIcon,
} from './style';

class LikeBox extends React.Component {
  constructor(props) {
    super(props);
    this.iconRef = React.createRef();
  }

  static propTypes = {
    type: PropTypes.oneOf(['heart', 'hug', 'sparkle']).isRequired,
    count: PropTypes.number.isRequired,
  };

  render() {
    const { type, count } = this.props;
    const ICONS = {
      heart: <HeartIcon className="icon" />,
      hug: <HugIcon className="icon" />,
      sparkle: <SparkleIcon className="icon" />,
    };

    return (
      <LikeBoxContainer ref={this.iconRef}>
        <LikeText className="likeText">{type}</LikeText>
        {ICONS[type]}
        <LikeCount>{count}</LikeCount>
      </LikeBoxContainer>
    );
  }
}

export default LikeBox;

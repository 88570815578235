import styled from '@emotion/styled';
import { SPACER, MEDIA_QUERIES, GRID } from 'radiance-ui/lib/constants';

import { LOCAL_COLORS, LOCAL_TYPOGRAPHY_CONSTANTS } from 'styles/constants';

import headerBackImage from './content/images/communityHeaderBackground.jpg';

export const HeaderBox = styled.div`
  background-color: ${LOCAL_COLORS.greyBackground};
  background-image: url(${headerBackImage});
  background-position: 40% -130px;
  background-repeat: no-repeat;
  background-size: 800px;
  height: 600px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0 ${SPACER.xlarge} ${SPACER.x5large} ${SPACER.xlarge};

  ${MEDIA_QUERIES.xsUp} {
    background-position: 40% -100px;
  }

  ${MEDIA_QUERIES.mdUp} {
    background-position: -300% -80px;
    background-size: 800px;
  }

  ${MEDIA_QUERIES.lgUp} {
    align-items: center;
    padding-bottom: 0px;
    padding-top: 70px;
    background-position: 300px -100px;
  }

  ${MEDIA_QUERIES.xlUp} {
    background-position: 80% -140px;
    background-size: 1100px;
    padding-top: 140px;
  }
`;

export const TitleContainer = styled.h1`
  font-weight: ${({ theme }) => theme.TYPOGRAPHY.fontWeight.normal};
  font-family: ${({ theme }) => theme.FONTS.headerFont};
  font-size: ${LOCAL_TYPOGRAPHY_CONSTANTS.fontSize.titleLg};
  color: ${({ theme }) => theme.COLORS.white};
  line-height: 60px;
  letter-spacing: -1.5px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  max-width: ${GRID.xlContentMaxWidth};
  margin: 0 auto;
  width: 100%;

  ${MEDIA_QUERIES.xsUp} {
    letter-spacing: 0;
  }

  ${MEDIA_QUERIES.mdUp} {
    font-size: ${LOCAL_TYPOGRAPHY_CONSTANTS.fontSize.titleXlg};
  }

  ${MEDIA_QUERIES.lgUp} {
    padding: 0 ${SPACER.x5large};
  }

  ${MEDIA_QUERIES.xlUp} {
    line-height: 1.5;
  }
`;

export const Title1 = styled.div`
  ${MEDIA_QUERIES.xsUp} {
    padding-left: ${SPACER.large};
  }

  ${MEDIA_QUERIES.xlUp} {
    padding-left: 130px;
  }
`;

export const Title3 = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  ${MEDIA_QUERIES.xsUp} {
    padding-left: 75px;
  }

  ${MEDIA_QUERIES.mdUp} {
    padding-left: 250px;
  }

  ${MEDIA_QUERIES.lgUp} {
    padding-left: 280px;
  }
`;

export const TitleHr = styled.span`
  width: 75px;
  padding-bottom: 0;
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.white};
  margin-left: ${SPACER.medium};

  ${MEDIA_QUERIES.xlUp} {
    width: 125px;
  }
`;

export const Title4 = styled.div`
  text-align: right;

  ${MEDIA_QUERIES.mdUp} {
    padding-right: ${SPACER.x2large};
  }

  ${MEDIA_QUERIES.xlUp} {
    padding-right: 0px;
    text-align: left;
    padding-left: 760px;
  }

  ${MEDIA_QUERIES.xxlUp} {
    padding-left: 390px;
  }
`;

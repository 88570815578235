import React from 'react';

import {
  HeaderBox,
  TitleContainer,
  Title1,
  Title3,
  Title4,
  TitleHr,
} from './style';

const Header = () => (
  <HeaderBox>
    <TitleContainer>
      <Title1>A community</Title1>
      <div>for every stage</div>
      <Title3>
        of your <TitleHr />
      </Title3>
      <Title4>journey</Title4>
    </TitleContainer>
  </HeaderBox>
);

export default Header;

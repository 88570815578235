import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import {
  SocialBox,
  TextCol,
  TextBox,
  Header,
  Subheader,
  ImageCol,
  SocialBackground,
  socialImageStyles,
} from './style';

const Social = () => (
  <SocialBox>
    <TextCol>
      <TextBox>
        <Header>Want to celebrate your skin?</Header>
        <Subheader>
          Share your story loud and proud. Just tag #curologyjourney, and follow
          us on social media!&nbsp;&nbsp;&nbsp;
          <span role="img" aria-label="icon">
            🎉
          </span>
        </Subheader>
      </TextBox>
    </TextCol>
    <ImageCol>
      <SocialBackground />
      <StaticImage
        src="./content/images/socialImage.jpg"
        alt="Curology Social Post"
        css={socialImageStyles}
        width={260}
      />
    </ImageCol>
  </SocialBox>
);

export default Social;

import React from 'react';
import PropTypes from 'prop-types';
import { InView } from 'react-intersection-observer';

import { MessageBox } from './style';

const AnimatedMessageBox = ({ reverse, children }) => (
  <InView rootMargin="-100px" triggerOnce>
    {({ inView, ref }) => (
      <MessageBox reverse={reverse} visible={inView} ref={ref}>
        {children}
      </MessageBox>
    )}
  </InView>
);

AnimatedMessageBox.propTypes = {
  reverse: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default AnimatedMessageBox;
